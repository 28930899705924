import request from '@/utils/request'


// 查询BGM设备列表
export function listBgmDevice(query) {
  return request({
    url: '/bgmDevice/bgmDevice/list',
    method: 'get',
    params: query
  })
}

// 查询BGM设备分页
export function pageBgmDevice(query) {
  return request({
    url: '/bgmDevice/bgmDevice/page',
    method: 'get',
    params: query
  })
}

// 查询BGM设备详细
export function getBgmDevice(data) {
  return request({
    url: '/bgmDevice/bgmDevice/detail',
    method: 'get',
    params: data
  })
}

// 新增BGM设备
export function addBgmDevice(data) {
  return request({
    url: '/bgmDevice/bgmDevice/add',
    method: 'post',
    data: data
  })
}

// 修改BGM设备
export function updateBgmDevice(data) {
  return request({
    url: '/bgmDevice/bgmDevice/edit',
    method: 'post',
    data: data
  })
}

// 删除BGM设备
export function delBgmDevice(data) {
  return request({
    url: '/bgmDevice/bgmDevice/delete',
    method: 'post',
    data: data
  })
}
